.root {
  width: 100%;
  margin-top: 30px;

  @media (max-width: 600px) {
    margin-top: 5px;
  }
}

.container {
  padding: 0px 15px 20px 15px;
  display: flex;
  gap: 5px;


  button {
    min-height: 36px;
  }


  @media (max-width: 600px) {
    padding-top: 15px;
  }
}
.root {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}


.header {
  display: flex;
  padding: 20px;
  align-items: center;
  background-color: #000;
  gap: 15px;
}


.avatar {
  width: 45px;
  height: 45px;
}


.caption {
  color: #fff;
  font-size: 0.7rem;
}


.title {
  margin: 0;
  color: #fff;
  font-size: 1.7rem;
  line-height: 1;
}


.messageContainer {
  padding: 30px;
}


.input {
  min-height: 200px;
}


.senderSelectionContainer {
  padding: 0px 20px 20px 20px;
}


.buttonContainer {
  padding: 20px;
}
.root {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 15px 5px 15px 15px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 100%;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.8);

  .imageContainer {
    width: 44px;
    height: 44px;
    border-radius: 7px;
    margin-right: 10px;
    flex-shrink: 0;
    position: relative;

    .image {
      width: 44px;
      height: 44px;
      object-fit: cover;
      overflow: hidden;
      border-radius: 7px;
    }

    .musicServiceIcon {
      position: absolute;
      z-index: 10;
      width: 22px;
      height: 22px;
      bottom: -9px;
      right: -7px;
      color: #fc3c44;
      background-color: #fff;
      border-radius: 50%;
    }
  }


  .infoContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    h3 {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.1;
      padding-right: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    p {
      font-size: 0.9rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }


  .controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    gap: 2px;


    .button {
      width: 35px;
      height: 35px
    }


    .playButton {
      width: 40px;
      height: 40px;
      background-color: rgb(255, 0, 149);
      color: #fff;
    }
  }
}



.rootFullPlayer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 40px 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  
  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: grey;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    max-width: 250px;
    width: 100%;
    height: 100%;
  }


  .imageContainer {
    position: relative;
    border-radius: 50%;
    box-shadow: 3px 38px 61px -15px rgba(255, 0, 149, 0.40);
    margin-bottom: 30px;


    .musicServiceIcon {
      position: absolute;
      z-index: 10;
      width: 40px;
      height: 40px;
      bottom: -20px;
      right: calc(50% - 20px);
      color: #fc3c44;
      background-color: #fff;
      border-radius: 50%;
    }
  }


  .infoContainer {
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;


    h3 {
      font-size: 1.1rem;
      font-weight: 600;
      line-height: 1.2;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }


    p {
      font-size: 1rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }


  .infoButtonsContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 7px;
  }


  .trackSlider {
    width: 100%;
  }


  .controlsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 10px;


    .button {
      width: 48px;
      height: 48px;
    }


    .playButton {
      color: #fff;
      background-color: rgb(255, 0, 149);
      box-shadow: 0px 10px 20px -3px rgba(255, 0, 149, 0.45);
    }
  }


  .volumeContainer {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}


















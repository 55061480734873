.root {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  div {
    display: flex;
    flex-direction: column;
    max-width: 520px;
    width: 100%;
  }

  h3 {
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1;
    color: #fff;
  }

  .caption {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }

  form {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  input {
    height: 50px;
    background-color: #fff;
    border: none;
    color: #000;
    flex-grow: 1;
    padding: 10px 20px;
    font-size: 1rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  button {
    padding: 10px 20px;
    background-color: rgb(255, 0, 149);
    border: none;
    height: 50px;
    cursor: pointer;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
    min-width: 100px;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
  }

  .error {
    font-size: 0.9rem;
    color: #f1807e;
    padding-left: 20px;
  }

  .success {
    color: #90EE90;
    font-size: 0.9rem;
    padding-left: 20px;
  }
}


@media (max-width: 992px) {
  .root {
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .root {
    div {
      width: 100%;
    }

    input {
      width: 100%;
      min-width: 0;
    }
  }
}

.compactRoot {
  position: fixed;
  top: 30px;
  right: 5px;
  z-index: 100;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 8px;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  width: 280px;
  align-items: stretch;



  .imageContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    cursor: pointer;


    .image {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      object-fit: cover;
    }


    .hole {
      position: absolute;
      background-color: #fff;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      z-index: 11;
    }


    .musicServiceIcon {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 11;
      width: 25px;
      height: 25px;
      color: #fc3c44;
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
    }
  }


  .controlsContainer {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;


    .playButton {
      width: 50px;
      height: 50px;
      background-color: rgb(255, 0, 149);
      color: #fff;
      box-shadow: 0px 10px 20px -3px rgba(255, 0, 149, 0.55);
    }
  }



  .buttonsContainer {
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    
    .closeIcon {
      width: 20px;
      color: grey;
      cursor: pointer;
    }


    .expandIcon {
      color: grey;
      cursor: pointer;
    }
  }
}




.root {
  position: fixed;
  top: 30px;
  right: 5px;
  z-index: 100;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  width: 306px;
  height: 544px;
  animation-name: slide-in;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;



  @media (max-width: 600px) {
    position: static;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    animation: none;
    height: 100%;
    max-height: 600px;
  }



  .closeIcon {
    position: absolute;
    cursor: pointer;
    top: 5px;
    right: 5px;
    color: grey;
    z-index: 31;
  }


  .videoContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    background-color: black;
    border-radius: 8px;
    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }


  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    min-height: 150px;
    min-width: 150px;
    border-radius: 50%;
    box-shadow: 3px 38px 61px -18px rgba(255, 0, 149, 0.51);
    margin-bottom: 15px;
    position: relative;


    .image {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      object-fit: cover;
      animation-name: spin;
      animation-duration: 5000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      z-index: 10;
    }


    .hole {
      position: absolute;
      background-color: #fff;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      z-index: 11;
    }


    .musicServiceIcon {
      position: absolute;
      z-index: 11;
      width: 36px;
      height: 36px;
      bottom: -10px;
      right: 10px;
      color: #fc3c44;
      background-color: #fff;
      border-radius: 50%;
    }
  }


  .infoContainer {
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    height: 46px;


    h3 {
      font-size: 1.1rem;
      font-weight: 600;
      line-height: 1.2;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }


    p {
      font-size: 1rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }


  .buttonsContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 3px;
    height: 40px;

    button {
      height: 34px;
      width: 34px;
    }

    a {
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.54);
      width: 34px;
      height: 34px;
    }
  }


  .trackSlider {
    width: 100%;
  }


  .controlsContainer {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 7px;
    margin-bottom: 7px;
    bottom: 0px;
    z-index: 30;


    .largeControls {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      margin-bottom: 25px;

      .playButton {
        width: 50px;
        height: 50px;
        background-color: rgb(255, 0, 149);
        color: #fff;
        box-shadow: 0px 10px 20px -3px rgba(255, 0, 149, 0.55);
      }

      .button {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }


    .smallControls {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0px 20px;

      .button {
        width: 50px;
        height: 50px;
      }
    }
  }


  .volumeContainer {
    width: 100%;
    max-width: 140px;
    flex-grow: 1;
  }


  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }


  @keyframes slide-in {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0%);
    }
  }


  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    
    to {
      transform: translateY(0%);
    }
  }
}





#footer {
  background-color: #000;
  min-height: 400px;
  padding: 100px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  a {
    text-decoration: none;
    color: #fff;
  }

  .container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    font-size: 0.9rem;
    color: rgb(196, 196, 196);
    margin-bottom: 30px;
    gap: 100px;
  }


  .column {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 25px;


    h5 {
      font-family: 'Gravity';
      font-size: 1.5rem;
      font-stretch: expanded;
      letter-spacing: -0.7px;
      color: #fff;
      margin: 0;
    }

    .company path {
      fill: #fff;
    }

    .contact {
      width: 100%;
      display: flex;
      flex-direction: column;

      h4 {
        color: #fff;
        margin: 0;
        margin-bottom: 1px;
      }
    }


    .socialMediaContainer {
      display: flex;
      gap: 10px;
      flex-direction: column;

      .icons {
        display: flex;
        gap: 10px;

        .instagramIcon {
          background-color: #272626;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .tikTokIcon {
          background-color: #272626;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .youtubeIcon {
          background-color: #272626;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      a {
        text-decoration: none;
      }

      a svg {
        display: block;
      }

      h4 {
        color: #fff;
        margin: 0;
        margin-bottom: 1px;
      }
    }


    .downloadContainer {
      display: flex;
      gap: 10px;
      width: 100%;
      justify-content: flex-end;


      .androidContainer {
        display: flex;
        height: 50px;
        min-width: 150px;
        gap: 10px;
        background-color: #272626;
        align-items: center;
        padding: 10px 20px;
        border-radius: 25px;
        color: #fff;

        div {
          display: flex;
          flex-direction: column;

          p {
            line-height: 0.8;
          }

          span {
            font-size: 0.8rem;
            font-style: italic;
          }
        }
      }


      .link {
        height: 50px;
        width: 150px;
        background-color: #272626;
        border-radius: 22px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        padding: 10px 20px;
        gap: 10px;
        font-size: 1rem;
        color: #fff;

        a svg {
          display: block;
        }

        span {
          font-size: 0.8rem;
        }
      }
    }
  }



  .horizontalLineContainer {
    width: 100%;
    max-width: 1200px;

    hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid #434343;
      margin: 1em 0;
      padding: 0;
    }
  }


  .lastRow {
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;


    .lastRowLinks {
      font-size: 0.9rem;;
      display: flex;
      gap: 20px;
    }
  }
}



@media (max-width: 992px) {
  #footer {
    .container {
      flex-direction: column;
      gap: 40px;


      .downloadContainer {
        justify-content: flex-start;
        margin-top: 40px;
      }
    }

    .column {
      width: 100%;
    }
  }
}


@media (max-width: 768px) {
  #footer {
    padding: 100px 40px;
  }
}




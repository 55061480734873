.root {
  width: 100%;
  min-height: calc(100vh + 10px);
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: stretch;
  justify-content: stretch;
  overflow: hidden;



  @media (max-width: 600px) {
    overflow: clip;
  }
  

  .top {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #fff;
    padding: 170px 25px 100px 25px;
    min-height: 50%;
    background-color: #000;


    @media (max-width: 1120px) {
      padding: 0px 25px 20px 25px;
      margin: 80px 0 30px 0px;
    }

    @media (max-width: 768px) {
      padding: 0;
      margin-top: 60px;
    }
  }
  


  .topContent {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: flex-start;
    max-width: 1100px;


    @media (max-width: 1120px) {
      flex-direction: column;
      align-items: center;
      max-width: 550px;
    }
  }



  .leftSide {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    z-index: 9;


    @media (max-width: 1120px) {
      max-width: 550px;
    }
  }
  

  .rightSide {
    width: 100%;
    flex-grow: 1;
    min-width: 450px;
    max-width: 500px;
    padding: 0 15px 0 40px;
    z-index: 10;


    @media (max-width: 1120px) {
      margin-top: -30px;
      padding: 0;
      min-width: 0px;
      max-width: 100%;
    }
  }


  .middle {
    position: sticky;
    width: 100%;
    height: 50px;
    z-index: 50;
    background-color: #000;
    top: 60px;

    @media (max-width: 1120px) {
      top: 124px;
    }

    @media (max-width: 600px) {
      top: 60px;
    }
  }



  .middleContent {
    margin: 0 auto;
    width: 100%;
    max-width: 1100px;
    height: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
  }



  .imageContainer {
    position: relative;
    width: 100%;
    overflow: hidden;
    cursor: pointer;

    @media (max-width: 960px) {
      max-width: 550px;
    }
  }



  // Used only if the there is a video and a large screen
  .videoContainer {
    position: relative;
    width: 100%;
    transition: height 0.3s ease;


    .videoImageContainer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 4;


      @media (max-width: 1120px) {
        position: relative;
      }
    }


    .videoAspectOuterContainer {
      position: relative;
      width: 100%;
      z-index: 5;


      .videoAspectInnerContainer {
        position: relative;


        .videoAspectRatioBox {
          width: 100%;
          height: 0;
          position: relative;
          overflow: hidden;
          transition: padding-top 0.3s ease;
        }


        .videoAspectRatioBoxInside {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }


        video {
          width: 100%;
          height: 100%;
          cursor: pointer;
          z-index: 5;
          object-fit: cover;
          transform: scale(0);
          transition: transform 0.3s ease;
        }
      }


      @media (max-width: 1120px) {
        position: absolute;
        top: 0;
      }
    }


    @media (max-width: 960px) {
      max-width: 550px;
    }
  }



  .gradient {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, transparent, transparent, transparent, rgba(0, 0, 0, 0.4), #000);
  }


  .bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #fff;
    flex-grow: 1
  }



  .bottomContent {
    width: 100%;
    max-width: 1100px;
    min-height: calc(100vh - 110px);
    margin: 0 auto;
    display: flex;
    align-items: flex-start;


    @media (max-width: 1120px) {
      padding: 0 25px;
    }

    @media (max-width: 600px) {
      padding: 0 0 150px 0;
    } 
  }
}



.dialogImage {
  width: 100%;
  max-height: 100%;
  object-fit: scale-down;
}



.detailModal {
  position: fixed;
  top: 0;
}



.videoCloseButton {
  position: absolute;
  cursor: pointer;
  z-index: 12;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}



.videoControls {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 10px;
  z-index: 12;
  opacity: 0;
  transition: transform 0.3s ease;
  transition: opacity 0.3s ease;


   @media (max-width: 1120px) {
    bottom: 30px;
  } 
}


.expandButton, .soundButton {
  border: none;
  width: 45px;
  height: 50px;
  z-index: 12;
  cursor: pointer;
  background-color: transparent;
}




.root {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}


.contentContainer {
  display: flex;
  align-items: flex-start;
}


.icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
}


.title {
  font-weight: 600;
  margin: 0;
  font-size: 1rem;
}


.text {
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 100%;
  line-height: 1.3;
  margin: 0;
  color: #c2c2c2;
  font-family: neue-haas-unica, 'Helvetica Neue', Roboto, -apple-system, Arial, sans-serif;
}
.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7px;
}

.container {
  width: 100%;
  min-height: 64px;
  background: rgb(38, 38, 38);
  border-radius: 10px;
  color: #fff;
  padding: 20px;

  .placeholder {
    color: #787878;
    letter-spacing: -0.02rem;
  }

  p {
    line-height: 21px;
    letter-spacing: -0.03rem;
  }
}


.form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  textarea {
    border: none;
    font-size: 1rem;
    line-height: 21px;
    background-color: transparent;
    color: #fff;
    background-color: #404040;
    border-radius: 10px;
    min-height: 84px;
    padding: 10px 15px;
    font-family: neue-haas-unica;
  }
}


.buttonContainer {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  align-items: center;
}

.button {
  background-color: transparent;
  border: none;
  color: rgb(62, 166, 255);
  text-transform: uppercase;
  padding: 0;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
}


.iconsContainer {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  gap: 5px;

  
  button {
    width: 34px;
  }

  svg {
    fill: #696969;
  }

  hr {
    margin: 5px 10px;
    background-color: #696969;
    border-right: 1px solid #696969;
    border-left: 1px solid #696969;
    border-top: 1px solid #696969;
    border-bottom: 1px solid #696969;
  }
}

.error {
  font-size: 0.9rem;
  color: #f1807e;
  padding-left: 20px;
}

.success {
  color: #90EE90;
  font-size: 0.9rem;
  padding-left: 20px;
}







.gradientButtonWrapper {
  display: flex;
  align-items: flex-start;
  background-color: black;
  overflow: hidden;


  .gradientButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    outline: none;
    position: relative;
    z-index: 1;
    height: 48px;
    min-width: 48px;
    border-radius: 50%;
    background: linear-gradient(to right, #ff0095, #fbde54, #DC1FFF);
    background-size: 200% 200%;
    cursor: pointer;
    color: white;
    text-transform: uppercase;
    animation: gradient-animation 3s ease 3;


    &::before {
      content: '';
      position: absolute;
      left: 3px;
      right: 3px;
      top: 3px;
      bottom: 3px;
      border-radius: 50%;
      background-color: black;
      height: 42px;
      z-index: -1;
      transition: 200ms;
    }


    &::after {
      font-size: 16px;
      background: linear-gradient(to left, #ff0095, #fbde54, #DC1FFF);
      -webkit-background-clip: text;
      color: transparent;
      transition: 200ms;
    }
    


    @keyframes gradient-animation {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }
}
@font-face {
  font-family: 'Gravity';
  src: url('../fonts/gravity.woff2') format('woff2 supports variations'),
       url('../fonts/gravity.woff2') format('woff2-variations');
  font-style: normal;
  font-stretch: 50% 150%;
  font-weight: 1 1000;
}


html, body {
  height: 100%;
  width: 100%;
}


body p, body h2, body h3 {
  margin: 0;
}


#app {
  min-height: 100%;
  width: 100%;
  height: 100%;
  background-color: #fff;
}


@media print {
  .hide-from-print {
    display: none;
  }
}


/* .gm-style .gmnoprint { */
/*   padding-bottom: 150px !important; */
/* } */


@keyframes drop {
  0% {
    transform: translateY(-200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.drop-animation {
  animation: drop 0.6s ease-out forwards;
}
.root {
  position: relative;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;


  .addressContainer {
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    gap: 10px;
  }


  .link {
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
  }


  .text {
    white-space: pre-wrap;
    word-wrap: break-word;
    max-width: 100%;
    line-height: 1.3;
    margin: 0;
    font-family: neue-haas-unica, 'Helvetica Neue', Roboto, -apple-system, Arial, sans-serif;
    color: #c2c2c2;
    cursor: pointer;
  }


  .imagesContainer {
    margin-bottom: 10px;
    max-height: 150px;

    & > div {
      padding-right: 150px;
    }
  }


  .imageContainer {
    display: flex;
    flex-wrap: nowrap;
    gap: 0px 10px;
    width: 100%;
  }


  .image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 5px;
    object-fit: cover;
    object-position: center center;
    cursor: pointer;
  }


  .slide {
    width: 150px;
    height: 150px;
    position: relative;

    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }


  @media (max-width: 1120px) {
    .addressContainer {
      padding: 0 25px;
    }

    .imagesContainer > div {
      overflow: visible;
      padding: 0 100px 0 25px;
    }
  }

  @media (max-width: 600px) {
    .slide {
      width: 120px;
      height: 120px;
    }
  }
}


.modalContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;


  .modalImage {
    object-fit: contain;
    object-position: center center;
    max-width: 90vw;
    max-height: 80vh;
  }


  .leftIconButton {
    position: fixed;
    left: 10px;
    background-color: #fff;
  }


  .rightIconButton {
    position: fixed;
    right: 10px;
    background-color: #fff;
  }


  .directions {
    display: none;
  }


  @media (max-width: 768px) {
    .directions {
      display: block;
      position: fixed;
      bottom: 36px;
      color: #fff;
    }


    .leftIconButton {
      left: 30px;
      bottom: 20px;
      background-color: #fff;
    }


    .rightIconButton {
      position: fixed;
      right: 30px;
      bottom: 20px;
    }
  }
}








.card {
  width: 200px;
  height: 330px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px;
}



.imageContainer {
  width: 100%;
  height: 200px;
  border-radius: 6px 6px 0px 0px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
    
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}



.infoContainer {
  padding: 12px 12px 0px 12px;
  flex-grow: 1; 
  min-width: 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content:  flex-start;
  align-items: flex-start;

  h3 {
    width: 100%;
    max-width: 100%;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.1;
    padding-right: 5px;
    margin-bottom: 1px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}



.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 52px;
  padding: 4px;
}



.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1
  }

  .iconContainer {
    background-color: black;
    opacity: 0.8;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    color: white;
    font-size: 50px;
  }
}


@media (max-width: 600px) {
  .card {
    width: 100%;
    max-width: 100vw;
    height: auto;
    padding: 10px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    overflow: hidden;
    cursor: pointer;
    border-radius: 0px;
    box-shadow: none;
    transition: 0.3s;
  }

  .imageContainer {
    width: 80px;
    height: 80px;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 10px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px;
  }

  .infoContainer {
    padding: 12px;
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;


    h3 {
      width: 100%;
      max-width: 100%;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.1;
      padding-right: 5px;
      margin-bottom: 1px;
    }

    p {
      color: #7b7b7b;
    }
  }

  .buttons {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 52px;
    padding: 4px;
    margin: 0;
  }
}






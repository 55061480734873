.pac-container {
  z-index: 100000 !important;
}

.pac-item {
  padding: 5px 10px;
  font-family: 'neue-haas-unica, sans-serif', 'Helvetica Neue', 'Arial';
  cursor: pointer;
}

.pac-icon-marker {
  display: none;
}

/* Selector for items */
/*.pac-item-query {}

.pac-matched {}*/